<template>
    <div>
        <CRow>
            <CCol lg="12">
                
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="7">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="cog"/>  Manage Matrix
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>


                        <template v-if="tsm.is_active">
                            <CButton 
                                size="sm"
                                color="danger" 
                                class="float-right" 
                                @click="toggleEdit(), returnInformation()" 
                                v-show="edit"
                            >
                                <font-awesome-icon icon="window-close"/> Cancel
                            </CButton>

                            <CButton 
                                :disabled="!isValid || !hasMadeChanges || updating_matrix"
                                v-if="$store.getters['can']('update-template-signatory-matrix')"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                v-show="edit" 
                                @click="updateTemplateSignatoryMatrix()"
                            >
                            <font-awesome-icon :icon="updating_matrix ? 'circle-notch' : 'save'" 
                            :pulse="updating_matrix"/> {{ updating_matrix ? 'Saving Changes...' : 'Save Changes' }}
                            </CButton>

                            <CButton 
                                v-if="$store.getters['can']('edit-template-signatory-matrix')"
                                size="sm"
                                color="info" 
                                class="float-right" 
                                @click="toggleEdit" 
                                v-show="!edit"
                            >
                                <font-awesome-icon icon="edit"/> Edit
                            </CButton>
                        </template>
                        <template v-else>
                            <CButton 
                                v-if="$store.getters['can']('restore-template-signatory-matrix')"
                                :disabled="restoring_matrix"
                                size="sm"
                                color="success" 
                                class="float-right" 
                                @click="restoreTemplateSignatoryMatrix()"
                            >
                            <font-awesome-icon :icon="restoring_matrix ? 'circle-notch' : 'trash-restore'" 
                            :pulse="restoring_matrix"/> {{ restoring_matrix ? 'Restoring...' : 'Restore' }}
                            </CButton>
                        </template>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                        <CCol lg="12">
                            <CInput 
                                :disabled="!edit"
                                :lazy="false"
                                :value.sync="$v.tsm.name.$model"
                                :isValid="checkIfValid($v.tsm, 'name')"
                                label="Name" 
                                type="text" 
                                autocomplete="off" 
                                v-nospecialcharacter
                                invalidFeedback="Must be three(3) characters."
                                v-tocapitalize
                                v-model="tsm.name"
                            >
                            </CInput>
                        </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CTextarea
                                    :disabled="!edit"
                                    :lazy="false"
                                    :value.sync="$v.tsm.description.$model"
                                    :isValid="checkIfValid($v.tsm, 'description')"
                                    v-model="tsm.description"
                                    class="grp-text-area"
                                    label="Description"
                                    placeholder="Content..."
                                    autocomplete="off"  
                                    v-tocapitalize
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                        <!-- <CRow>
                        <CCol col="12" class="text-right">
                            <CButton 
                                :disabled="!isValid"
                                title="There is required field that is not filled out or the Attachment Checklist is set to true but there is no entry made."
                                type="submit" 
                                shape="pill" 
                                color="primary"
                            >
                                <font-awesome-icon icon="save"/> Save
                            </CButton>
                        </CCol>
                    </CRow> -->
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow v-show="edit">
            <CCol lg="7">
                <CCard borderColor="danger" v-if="$store.getters['can']('archive-template-signatory-matrix')">
                    <CCardHeader>
                        <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/> 
                                Danger Zone
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <h6>Archive this Template Signatory Matrix ?</h6>
                            </CCol>
                            <CCol lg="12">
                                <label> Once archived, you cannot use this matrix anymore. Please be certain.</label>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                <CButton 
                                    :disabled="archiving_matrix"
                                    shape="pill" 
                                    color="warning" 
                                    @click="archiveTemplateSignatoryMatrix()" > 
                                    <font-awesome-icon :icon="archiving_matrix ? 'circle-notch' : 'minus-square'" 
                                    :pulse="archiving_matrix"/> {{ archiving_matrix ? 'Archiving...' : 'Archived' }}
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <template v-if="can_view_template_signatory_matrix_purpose">
            <CRow>
                <CCol lg="12">
                    <CCard>
                        <CCardHeader><font-awesome-icon icon="fa-barcode"/> Matrix Purposes
                            <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                        </CCardHeader>
                        <CCardBody>
                            <template v-if="can_add_template_signatory_matrix_purpose && tsm.is_active">
                                <CRow>
                                    <CCol lg="1"/>
                                    <CCol lg="7">
                                        Name
                                    </CCol>
                                    <CCol lg="2">
                                        Sequence <a-popover title="WARNING!" placement="left">
                                            <template slot="content">
                                                Duplicate sequence detected. If you wish to continue, the sequence <br>
                                                in the list will be automatically adjusted.
                                            </template>
                                            <font-awesome-icon 
                                                v-if="duplicate_sequence"
                                                style="color: orange; margin-right:10px" 
                                                icon="info-circle" 
                                                size="lg"/>
                                        </a-popover>
                                    </CCol>
                                    <CCol lg="1"/>
                                    <CCol lg="1"/>
                                </CRow>
                                <CRow>
                                    <CCol lg="1"/>
                                    <CCol lg="7">
                                        <CInput 
                                            type="text" 
                                            autocomplete="off" 
                                            v-model="add.purpose"
                                            >
                                        </CInput>
                                    </CCol>
                                    <CCol lg="2">
                                        <CInput 
                                            type="text" 
                                            maxlength="2"
                                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                            autocomplete="off" 
                                            v-model="add.sequence"
                                            >
                                        </CInput>
                                        
                                    </CCol>
                                    <CCol lg="1">
                                        <CButton
                                            :disabled="!add.purpose || !add.sequence"
                                            color="primary"
                                            @click="addPurpose()"
                                        >
                                            <font-awesome-icon icon="plus"/>
                                        </CButton>
                                    </CCol>
                                    <CCol lg="1"/>
                                </CRow>
                            </template>
                            <CRow>
                                <CCol lg="12">
                                    <CDataTable
                                        :key="purpose_list_key"
                                        :items="purpose_list"
                                        :fields="purpose_list_field"
                                        :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                        :items-per-page="10"
                                        border
                                        items-per-page-select
                                        sorter
                                        pagination
                                    >
                                    <template #purpose="{item}">
                                        <td>
                                            <template v-if="can_update_template_signatory_matrix_purpose && tsm.is_active">
                                                <CInput 
                                                    type="text" 
                                                    autocomplete="off" 
                                                    v-model="item.purpose"
                                                    >
                                                </CInput>
                                            </template>
                                            <template v-else>
                                                {{item.purpose}}
                                            </template>
                                        </td>
                                    </template>
                                    <template #sequence="{item}">
                                        <td>
                                            <template v-if="can_update_template_signatory_matrix_purpose && tsm.is_active">
                                                <CRow>
                                                    <CCol lg="12">
                                                        <CInput 
                                                            type="text" 
                                                            maxlength="2"
                                                            onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                            autocomplete="off"
                                                            v-model="item.sequence"
                                                            >
                                                        </CInput>
                                                    </CCol>
                                                    <CCol lg="0">
                                                        <!-- <a-popover title="WARNING!" placement="left">
                                                            <template slot="content">
                                                                Duplicate sequence detected. If you wish to continue, the sequence <br>
                                                                in the list will be automatically adjusted.
                                                            </template>
                                                            <font-awesome-icon 
                                                                v-if="sequence_list.include(item.sequence)"
                                                                style="color: orange;"
                                                                class="float-left" 
                                                                icon="info-circle" 
                                                                size="lg"/>
                                                        </a-popover> -->
                                                    </CCol>
                                                </CRow>
                                            </template>
                                            <template v-else>
                                                {{item.purpose}}
                                            </template>
                                        </td>
                                    </template>
                                        
                                        <template #action="{item, index}">
                                            <td>
                                                
                                                <template v-if="item.id && (purpose_list_backup[index].purpose != item.purpose || purpose_list_backup[index].sequence != item.sequence)">
                                                    <CButton color="warning" 
                                                        shape="pill" 
                                                        size="sm"
                                                        :disabled="updating_purpose && updating_purpose_id == item.id" 
                                                        @click="updatePurpose(item, index)">
                                                        <font-awesome-icon :icon="updating_purpose && updating_purpose_id == item.id ? 'circle-notch' : 'pencil'" 
                                                        :pulse="updating_purpose && updating_purpose_id == item.id"/>
                                                    </CButton>
                                                    &nbsp;
                                                </template>
                                                <template v-if="can_remove_template_signatory_matrix_purpose">
                                                    <CButton color="danger" 
                                                        shape="pill" 
                                                        size="sm"
                                                        :disabled="removing_purpose && removing_purpose_id == item.id" 
                                                        @click="removePurpose(item, index)">
                                                        <font-awesome-icon :icon="removing_purpose && removing_purpose_id == item.id ? 'circle-notch' : 'times'" 
                                                        :pulse="removing_purpose && removing_purpose_id == item.id"/>
                                                    </CButton>
                                                    <!-- <CButton color="danger" shape="pill" size="sm" @click="removePurpose(item, index)">
                                                        <font-awesome-icon icon="times"/> 
                                                    </CButton> -->
                                                </template>
                                            </td>
                                        </template>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </template>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'TemplateSignatoryMatrixEdit',
	data() {
		return {
            purpose_list: [],
            purpose_list_backup: [],
            purpose_list_key:0,
            
            edit: false,

            tsm: {
                id: null,
                name: null,
                description: null,
                is_active: null,
            },

            tsm_backup: {
                id: null,
                name: null,
                description: null,
                is_active: null,
            },

            add: {
                purpose: null,
                sequence: null,
            },

            updating_purpose: false,
            updating_purpose_id: null,

            removing_purpose: false,
            removin_purpose_id: null,

            updating_matrix: false,
            archiving_matrix: false,

            adding_purpose: false,

            restoring_matrix: false,

            sequence_list: [],
            sequence_list_backup: [],


		}
	},
    computed: {
        isValid () { return !this.$v.tsm.$invalid },
        isDirty () { return this.$v.tsm.$anyDirty },
        hasMadeChanges: function () {
            return JSON.stringify(this.tsm_backup) == JSON.stringify(this.tsm) ? false : true;
        },
        can_view_template_signatory_matrix_purpose: function (){
            return this.$store.getters['can']('view-template-signatory-matrix-purpose')
        },
        can_add_template_signatory_matrix_purpose: function (){
            return this.$store.getters['can']('add-template-signatory-matrix-purpose')
        },
        can_update_template_signatory_matrix_purpose: function (){
            return this.$store.getters['can']('update-template-signatory-matrix-purpose')
        },
        can_remove_template_signatory_matrix_purpose: function (){
            return this.$store.getters['can']('remove-template-signatory-matrix-purpose')
        },
        duplicate_sequence: function () {
            return this.purpose_list.filter(x => parseInt(x.sequence) == parseInt(this.add.sequence)).length > 0 ? true : false;
        },
        purpose_list_field: function () {
            let header = [
                {key: 'purpose'},
                {key: 'sequence'},
            ]

            if(this.tsm.is_active) {
                header.push({key: 'action'})
            }
            return header;
        }
    },
    created() {
        this.getMatrixDetails()
    },
    validations: {
        tsm: {
            name: { required, minLength: minLength(3) },
            description: { required },
        },
    },
	methods: {
        restoreTemplateSignatoryMatrix: function () {
            this.restoring_matrix = true;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to restore the <b>${this.tsm.name}</b>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        id: this.paramEncoder(this.tsm.id)
                    }
                    return axios.post('drs/template-signatory-matrix/restore', data, {validateStatus: () => true})
                        .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.tsm.name} successfully restored.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.tsm.is_active = true
                            this.restoring_matrix = false;
                            this.$Progress.finish()
                        }
                        this.restoring_matrix = false;
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.restoring_matrix = false;
                }
            })
        },
        archiveTemplateSignatoryMatrix: function () {
            this.archiving_matrix = true;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive the <b>${this.tsm.name}</b>`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        id: this.paramEncoder(this.tsm.id)
                    }
                    return axios.post('drs/template-signatory-matrix/archive', data, {validateStatus: () => true})
                        .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.tsm.name} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.tsm.is_active = false
                            this.archiving_matrix = false;
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                        this.archiving_matrix = false;
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.archiving_matrix = false;
                }
            })
        },
        updateTemplateSignatoryMatrix: function () {
            this.updating_matrix = true
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to update a template signatory matrix.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        id: this.paramEncoder(this.tsm.id),
                        name: this.tsm.name,
                        description: this.tsm.description
                    }
                    return axios.post('/drs/template-signatory-matrix/update', data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.tsm.name}</b> Successfully updated.`,
                            })

                            this.tsm_backup = JSON.parse(JSON.stringify(this.tsm))
                            this.toggleEdit();
                        }
                        
                        this.updating_matrix = false
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.updating_matrix = false
                }
            })
        },
        removePurpose: function (item, index) {
            this.purpose_list_key++;
            this.removing_purpose = true;
            this.removing_purpose_id = item.id
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to remove a purpose.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        id: this.paramEncoder(item.id),
                    }
                    return axios.post('/drs/template-signatory-matrix/purpose/remove', data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${item.purpose}</b> Successfully removed.`,
                            })
                            this.purpose_list.splice(index, 1);
                            this.purpose_list_backup.splice(index, 1)
                        }
                        
                        this.removing_purpose = false;
                        this.removing_purpose_id = null
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.removing_purpose = false;
                    this.removing_purpose_id = null
                }
            })
        },  
        addPurpose: function () {
            this.purpose_list_key++;
            let data = {
                has_duplicate: this.duplicate_sequence,
                purpose: this.add.purpose,
                sequence: this.add.sequence,
                tsm_id: this.$route.params.id,
            }
            // this.purpose_list.push({id: null, tsm_id: this.$route.params.id, purpose: this.add.purpose})
            axios.post('/drs/template-signatory-matrix/purpose/add', data, {validateStatus: () => true})
                .then(response => {
                if ( response.status == 200) {
                    let resp = response.data.data;
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${data.purpose} has been added.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    //ivan add
                    // let row = {
                    //     id: resp.id,
                    //     purpose: resp.purpose, 
                    //     sequence: resp.sequence, 
                    //     deleted_at: resp.deleted_at, 
                    //     description: resp.description ? resp.description : '', 
                    //     required: resp.required,
                    //     // ----
                    //     name_backup: resp.name, 
                    //     description_backup: resp.description ? resp.description : '', 
                    //     required_backup: resp.required,
                    //     document_routing_attachments_count: "0"
                    // }
                    this.purpose_list = resp;
                    this.purpose_list_backup = JSON.parse(JSON.stringify(this.purpose_list))
                } 
            })
            this.add.purpose = null
            this.add.sequence = null
        },
        returnInformation: function () {
            this.tsm = JSON.parse(JSON.stringify(this.tsm_backup))
        },
        toggleEdit: function () {
            this.edit = !this.edit
            if(this.edit) {
                this.$v.$touch()
            }
            else {
                this.$v.$reset()
            }
        },
        getMatrixDetails: function () {
            this.$Progress.start()
            axios.get(`/drs/template-signatory-matrix/${this.$route.params.id}`, {validateStatus: () => true})
            .then( response => {
                this.assignData(response.data.data)    
                this.$Progress.finish()
            });
        },
        assignData: function (data) {
            this.tsm.name = data.name;
            this.tsm.id = data.id;
            this.tsm.is_active = data.deleted_at ? false : true
            this.tsm.description = data.description;
            this.purpose_list = data.template_signatory_matrix_purpose

            this.sequence_list = this.purpose_list.map(item => parseInt(item.sequence));
            this.sequence_list_backup = JSON.parse(JSON.stringify(this.sequence_list))

            this.tsm_backup = Vue.util.extend({}, this.tsm);
            this.purpose_list_backup = JSON.parse(JSON.stringify(this.purpose_list))
        },
        updatePurpose: function (item, index) {


            let ids = this.purpose_list.map(item => item.sequence);
            let hasDuplicates = new Set(ids).size !== ids.length;


            let dups_mess = "Duplicate sequence detected, continuing to update this will result to automatically adjust the sequence. <br> Would you like to continue update?"
            let normal_mess = "You are trying to update a purpose."
            
            this.purpose_list_key++;
            this.updating_purpose_id = item.id
            this.updating_purpose = true;

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: hasDuplicates ? dups_mess : normal_mess,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let data = {
                        has_duplicate: hasDuplicates,
                        purpose: item.purpose,
                        sequence: item.sequence,
                        id: this.paramEncoder(item.id),
                        tsm_id: this.paramEncoder(this.tsm.id),
                    }
                    return axios.post('/drs/template-signatory-matrix/purpose/update', data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200) {
                            let resp = response.data.data;
                            
                            this.$Progress.finish()
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                icon: 'success',
                                title: `${data.purpose} has been updated.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            //ivan add
                            this.purpose_list = resp;
                            this.purpose_list_backup = JSON.parse(JSON.stringify(this.purpose_list))
                        } else {
                            item.purpose = this.purpose_list_backup[index].purpose
                        }
                        this.updating_purpose_id = null
                        this.updating_purpose = false;
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.updating_purpose_id = null
                    this.updating_purpose = false;
                }
            })
        }
	},
}
</script>